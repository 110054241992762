<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :height="20"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
    ></loading>
    <div class="mb-3">
      <b-button variant="primary" @click="generateDistributeNew()">
        <i class="fa fa-refresh"></i> Шинээр хувиарлах
      </b-button>
    </div>
    <b-card>
      <h4>Хугацаа хэтрэлт хувиарлах</h4>
      <hr />
      <b-row>
        <b-col class="col-md-4">
          <loading
            :active.sync="distributeListLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :height="20"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>

          <b-link
            @click="
              distributeConfigEdit({
                id: 0,
                minDay: 1,
                maxDay: 1,
                isActive: false,
              })
            "
            >нэмэх</b-link
          >
          <b-list-group class="mt-1">
            <b-list-group-item
              :key="index"
              v-for="(item, index) in distributeList"
            >
              <ConfigDistributeItem
                :item="item"
                :editback="distributeConfigEdit"
                :callback="getDistributeList"
              ></ConfigDistributeItem>
            </b-list-group-item>
            <b-list-group-item v-if="distributeList.length == 0">
              <b-alert>a</b-alert>
            </b-list-group-item>
          </b-list-group>
          <ConfigDistributeEdit
            :item="editDistributeConfig"
            :callback="getDistributeList"
          ></ConfigDistributeEdit>
        </b-col>
        <b-col class="col-md-4">
          <loading
            :active.sync="distributeCustomerListLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :height="20"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>

          <b-link
            v-if="this.$route.params.id > 0"
            @click="
              distributeCustomerConfigEdit({
                id: 0,
                operator: null,
                percent: 0,
                isActive: false,
              })
            "
            >нэмэх</b-link
          >
          <b-list-group class="mt-1">
            <b-list-group-item
              :key="index"
              v-for="(item, index) in distributeCustomerList"
            >
              <ConfigDistributeCustomerItem
                :item="item"
                :editback="distributeCustomerConfigEdit"
                :callback="getDistributeCustomerList"
              ></ConfigDistributeCustomerItem>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                distributeCustomerList.length == 0 && this.$route.params.id > 0
              "
            >
              <p class="mb-0 text-danger">Мэдээлэл байхгүй</p>
            </b-list-group-item>
          </b-list-group>
          <ConfigDistributeCustomerEdit
            :item="editDistributeCustomerConfig"
            :callback="getDistributeCustomerList"
          ></ConfigDistributeCustomerEdit>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import ConfigDistributeItem from "./ConfigDistributeItem";
import ConfigDistributeEdit from "./ConfigDistributeEdit";
import ConfigDistributeCustomerItem from "./ConfigDistributeCustomerItem";
import ConfigDistributeCustomerEdit from "./ConfigDistributeCustomerEdit";
export default {
  data: function() {
    return {
      isLoading: false,
      distributeId: this.$route.params.id,

      distributeList: [],
      editDistributeConfig: null,
      distributeListLoading: false,

      distributeCustomerList: [],
      editDistributeCustomerConfig: null,
      distributeCustomerListLoading: false,
    };
  },
  components: {
    ConfigDistributeItem,
    ConfigDistributeEdit,
    ConfigDistributeCustomerItem,
    ConfigDistributeCustomerEdit,
  },
  created() {
    this.getDistributeList();
    this.loadData();
  },
  watch: {
    $route(to, from) {
      this.loadData();
    },
  },
  methods: {
    loadData: function() {
      this.$data.distributeCustomerList = [];
      if (this.$route.params.id) {
        this.$data.distributeId = this.$route.params.id;
        this.getDistributeCustomerList();
      }
    },
    getDistributeList: async function() {
      this.$data.distributeList = [];
      this.$data.distributeListLoading = true;
      var response = await this.$http.get(
        this.$config.API_URL + "DistributeWebService/get_config_list",
        {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        }
      );
      this.$data.distributeListLoading = false;
      //console.log(response)

      this.$data.distributeList = response.body.data;
    },
    getDistributeCustomerList: async function() {
      this.$data.distributeCustomerList = [];
      this.$data.distributeCustomerListLoading = true;
      var response = await this.$http.get(
        this.$config.API_URL + "DistributeWebService/get_customer_config_list",
        {
          params: {
            distributeId: this.$data.distributeId,
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        }
      );
      this.$data.distributeCustomerListLoading = false;
      //console.log(response)

      this.$data.distributeCustomerList = response.body.data;
    },

    distributeConfigEdit: function(_item) {
      this.$data.editDistributeConfig = {
        id: _item.id,
        minDay: _item.minDay,
        maxDay: _item.maxDay,
        isActive: _item.isActive,
      };
    },

    distributeCustomerConfigEdit: function(_item) {
      this.$data.editDistributeCustomerConfig = {
        id: _item.id,
        operator: _item.sysUserId == undefined ? null : _item.sysUserId.id,
        percent: _item.percent,
        isActive: _item.isActive,
      };
    },

    generateDistributeNew: async function() {
      this.$data.isLoading = true;

      var response = await this.$http.post(
        this.$config.API_URL + "DistributeWebService/distribute_new",
        {},
        {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        }
      );
      this.$data.isLoading = false;
    },
  },
};
</script>
