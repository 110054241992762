<template>
  <div class="pull-left">
    <b-modal
      ref="my-modal"
      :title="this.item ? 'Засах' : 'Шинээр нэмэх'"
      hide-footer
    >
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
      <b-form @submit.stop.prevent="saveData()" v-if="form">
        <b-form-group label="Доод хоног">
          <money
            id="input-minDay"
            class="form-control"
            v-model="form.minDay"
            type="text"
            required
            placeholder="Доод хоног"
            suffix=" хоног"
            :precision="0"
          ></money>
        </b-form-group>
        <b-form-group label="Дээд хоног">
          <money
            id="input-maxDay"
            class="form-control"
            v-model="form.maxDay"
            type="text"
            required
            placeholder="Дээд хоног"
            suffix=" хоног"
            :precision="0"
          ></money>
        </b-form-group>
        <b-form-group id="input-group-4" class="mb-0">
          <b-form-checkbox :value="true" v-model="form.isActive" switch
            >Идэвхтэй</b-form-checkbox
          >
        </b-form-group>
        <hr />
        <b-button type="submit" variant="success">
          Хадгалах
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Merchant.conditionEdit",
  props: ["item", "callback", "isEdit"],
  data: function() {
    return {
      isLoading: false,
      form: null,
    };
  },
  watch: {
    item: {
      handler(_item) {
        this.$data.form = this.item;
        this.showModal();
      },
      deep: true,
    },
  },
  methods: {
    initData: function() {
      this.$data.isLoading = false;
      this.$data.item = null;
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.initData();
      this.$refs["my-modal"].hide();
    },
    saveData: async function() {
      this.$data.isLoading = true;

      var response = await this.$http.post(
        this.$config.API_URL +
          "DistributeWebService/distribute_config_data_change",
        {
          id: this.$data.form.id,
          data: JSON.stringify({
            minDay: this.$data.form.minDay,
            maxDay: this.$data.form.maxDay,
            isActive: this.$data.form.isActive,
          }),
        },
        {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        }
      );
      //console.log(response)
      this.$data.isLoading = false;
      this.hideModal();
      this.callback();
    },
  },
};
</script>
