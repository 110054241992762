<template>
  <div class="pull-left">
    <b-modal
      ref="my-modal"
      :title="this.item ? 'Засах' : 'Шинээр нэмэх'"
      hide-footer
    >
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
      <b-form @submit.stop.prevent="saveData()" v-if="form">
        <b-form-group label="Ажилтан" label-for="operator">
          <b-select v-model="form.operator">
            <option :value="null" selected disabled>-- Сонгоно уу --</option>
            <option
              :value="item.id"
              :key="index"
              v-for="(item, index) in operators"
              >@{{ item.firstname }}</option
            >
          </b-select>
        </b-form-group>
        <b-form-group id="input-group-4" class="">
          <b-form-checkbox :value="true" v-model="isOther" switch
            >Үлдсэн</b-form-checkbox
          >
        </b-form-group>
        <b-form-group label="Хувь" v-if="!isOther">
          <money
            id="input-maxDay"
            class="form-control"
            v-model="form.percent"
            type="text"
            required
            placeholder="Хувь"
            suffix=" %"
            :precision="0"
          ></money>
        </b-form-group>
        <b-form-group id="input-group-4" class="mb-0">
          <b-form-checkbox :value="true" v-model="form.isActive" switch
            >Идэвхтэй</b-form-checkbox
          >
        </b-form-group>

        <hr />
        <b-button type="submit" variant="success">
          Хадгалах
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Distribute.customerCongifEdit",
  props: ["item", "callback", "isEdit"],
  data: function() {
    return {
      isOther: false,
      isLoading: false,
      form: null,
      operators: [],
    };
  },
  mounted() {
    this.loadSysUserList();
  },
  watch: {
    isOther: {
      handler(_isOther) {
        if (!_isOther && this.$data.form.percent == -1) {
          this.$data.form.percent = 0;
        }
      },
      deep: true,
    },
    item: {
      handler(_item) {
        if (this.item.percent == -1) {
          this.$data.isOther = true;
        }
        this.$data.form = this.item;
        this.showModal();
      },
      deep: true,
    },
  },
  methods: {
    initData: function() {
      this.$data.isLoading = false;
      this.$data.item = null;
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.initData();
      this.$refs["my-modal"].hide();
    },
    loadSysUserList: function() {
      this.$http
        .get(this.$config.API_URL + "ReportWebService/get_operator_list", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            //console.log(response)
            this.$data.operators = response.body.data;
          },
          (response) => {}
        );
    },
    saveData: async function() {
      this.$data.isLoading = true;
      if (this.$data.isOther) {
        this.$data.form.percent = -1;
      }

      var response = await this.$http.post(
        this.$config.API_URL +
          "DistributeWebService/distribute_customer_config_data_change",
        {
          id: this.$data.form.id,
          distributeId: this.$route.params.id,
          data: JSON.stringify({
            operator: this.$data.form.operator,
            percent: this.$data.form.percent,
            isActive: this.$data.form.isActive,
          }),
        },
        {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        }
      );
      //console.log(response)
      this.$data.isLoading = false;
      this.hideModal();
      this.callback();
    },
  },
};
</script>
