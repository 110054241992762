var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":true,"color":this.$config.LOADER.color,"opacity":this.$config.LOADER.opacity,"height":20,"background-color":this.$config.LOADER.backgroundColor,"z-index":10000},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"mb-3"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.generateDistributeNew()}}},[_c('i',{staticClass:"fa fa-refresh"}),_vm._v(" Шинээр хувиарлах ")])],1),_c('b-card',[_c('h4',[_vm._v("Хугацаа хэтрэлт хувиарлах")]),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"col-md-4"},[_c('loading',{attrs:{"active":_vm.distributeListLoading,"is-full-page":false,"color":this.$config.LOADER.color,"opacity":this.$config.LOADER.opacity,"height":20,"background-color":this.$config.LOADER.backgroundColor,"z-index":10000},on:{"update:active":function($event){_vm.distributeListLoading=$event}}}),_c('b-link',{on:{"click":function($event){return _vm.distributeConfigEdit({
              id: 0,
              minDay: 1,
              maxDay: 1,
              isActive: false,
            })}}},[_vm._v("нэмэх")]),_c('b-list-group',{staticClass:"mt-1"},[_vm._l((_vm.distributeList),function(item,index){return _c('b-list-group-item',{key:index},[_c('ConfigDistributeItem',{attrs:{"item":item,"editback":_vm.distributeConfigEdit,"callback":_vm.getDistributeList}})],1)}),(_vm.distributeList.length == 0)?_c('b-list-group-item',[_c('b-alert',[_vm._v("a")])],1):_vm._e()],2),_c('ConfigDistributeEdit',{attrs:{"item":_vm.editDistributeConfig,"callback":_vm.getDistributeList}})],1),_c('b-col',{staticClass:"col-md-4"},[_c('loading',{attrs:{"active":_vm.distributeCustomerListLoading,"is-full-page":false,"color":this.$config.LOADER.color,"opacity":this.$config.LOADER.opacity,"height":20,"background-color":this.$config.LOADER.backgroundColor,"z-index":10000},on:{"update:active":function($event){_vm.distributeCustomerListLoading=$event}}}),(this.$route.params.id > 0)?_c('b-link',{on:{"click":function($event){return _vm.distributeCustomerConfigEdit({
              id: 0,
              operator: null,
              percent: 0,
              isActive: false,
            })}}},[_vm._v("нэмэх")]):_vm._e(),_c('b-list-group',{staticClass:"mt-1"},[_vm._l((_vm.distributeCustomerList),function(item,index){return _c('b-list-group-item',{key:index},[_c('ConfigDistributeCustomerItem',{attrs:{"item":item,"editback":_vm.distributeCustomerConfigEdit,"callback":_vm.getDistributeCustomerList}})],1)}),(
              _vm.distributeCustomerList.length == 0 && this.$route.params.id > 0
            )?_c('b-list-group-item',[_c('p',{staticClass:"mb-0 text-danger"},[_vm._v("Мэдээлэл байхгүй")])]):_vm._e()],2),_c('ConfigDistributeCustomerEdit',{attrs:{"item":_vm.editDistributeCustomerConfig,"callback":_vm.getDistributeCustomerList}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }